@import '../../index.scss';


.login-form-container {
  @extend %flex-center;
  flex-wrap: wrap;
  // align-items: flex-start;


  background-color: rgba(255, 255, 255, .9);
  height: 100vh;
  width: 100%;
  text-transform: none;

  .logoContainer {
    flex-basis: 100%;
    margin: 4rem;
    // margin-bottom: 4rem;
  }

  .logo {
    font-size: 4rem;
    font-weight: bolder;
    color: $black;
    @extend %flex-center;

    svg {
      color: $green;
    }
  }

  form {
    border: $border;
    background-color: #fff;
    width: 40rem;
    padding: 2rem;
    box-shadow: $box-shadow;
    border-radius: .5rem;
    margin: 2rem;

    h3 {
      font-size: 2.5rem;
      text-transform: uppercase;
      color: $black;
      text-align: center;
      margin-bottom: 2rem;

      svg {
        color: $green;
      }
    }

    span {
      display: block;
      font-size: 1.5rem;
      padding-top: 1rem;
      font-weight: bold;
    }

    .box {
      width: 100%;
      margin: .7rem 0;
      font-size: 1.6rem;
      border: $border;
      border-radius: .5rem;
      padding: 1rem 1.2rem;
      color: $black;
      text-transform: none;
      background-color: #ddd;
    }

    .checkbox {
      @extend %flex-center;
      justify-content: flex-start;
      gap: .5rem;
      padding: 1rem 0;

      label {
        font-size: 1.5rem;
        color: $light-color;
        cursor: pointer;
      }
    }

    .btn {
      width: 100%;
      margin: 1.5rem 0;
    }

    .btn.btn-block {
      display: block;
    }

    .btn-login--info {
      flex-basis: 100%;
    }

    p {
      padding-top: .8rem;
      color: $light-color;
      font-size: 1.5rem;

      a {
        color: $green;

        &:hover {
          color: $dark-color;
        }
      }
    }
  }

  .close-login-btn {
    position: absolute;
    padding: 1rem;
    top: 1rem;
    right: 2rem;
    font-size: 5rem;
    color: $black;
    cursor: pointer;

    &:hover {
      svg {
        color: $green;
      }
    }
  }

  .login-form-terms {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    p {
      text-transform: unset;
      display: inline-block;
      margin-left: 1rem;
    }

  }
}