@import '../../index.scss';

.search-result {
  font-size: 3rem;
  padding: .5rem;
  color: $black;
  margin-bottom: 2rem;



  span {
    font-size: 3rem;
    padding: .5rem 2rem;
    color: $black;
    background-color: #fff;

  }
}

.search-result__container {
  @extend %flex-center;
  flex-wrap: wrap;
}

.box-search-item {
  width: 25rem;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
  border: $border;
  text-align: center;

  &:hover {
    border: $border-hover;

    .icons {
      transform: translateY(0)
    }

    .image {
      transform: translateY(6rem);
    }
  }

  .image {
    padding: 1rem;
    background: linear-gradient(15deg, #eee 30%, #fff 30.1%);

    img {
      display: inline-block;
      width: 150px;
      height: 220px;
    }
  }

  .icons {
    padding-bottom: $border-hover;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    transform: translateY(-100%);

    a {
      @extend %flex-center;
      display: inline-block;
      color: $black;
      font-size: 2.2rem;
      margin: 1.5rem;
      padding: 1.3rem 1.5rem;

      &:hover {
        background-color: $green;
        color: #fff;
      }
    }
  }

  .content {
    background-color: #eee;
    padding: 1.5rem;

    h3 {
      font-size: 2rem;
      color: $black;
    }

    p {
      font-size: 1.5rem;
      color: $light-color;
    }

    .price {
      font-size: 2.2rem;
      color: $black;
      padding-top: 1rem;

      span {
        font-size: 1.5rem;
        color: $light-color;
        text-decoration: line-through;
      }
    }
  }

}

.search-result--count {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;

  p {
    font-size: 2rem;
    color: $black;
  }
}

.search-result-view-per-page {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  color: $black;

  select {
    margin-left: 1rem;
    border: 2px solid $green;
    font-size: 2rem;
    background-color: #fff;
    color: darken($color: $green, $amount: 20%);
    cursor: pointer;
  }
}

.pagination-container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &--controller {
    @extend %flex-center;
    margin: 0 1rem;
    padding: 1.5rem;
    border: 1px solid #000;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    svg {
      color: $green;
      scale: 3;
    }

    &:disabled {
      border-color: #777;
      cursor: auto;

      svg {
        color: #777;
      }
    }
  }

  &--pages {
    display: flex;

    div {
      font-size: 3rem;
    }

    p {
      @extend %flex-center;
      margin: 0 5px;

      span {
        display: inline-block;
        margin: 2px;
        width: 5px;
        height: 5px;
        background-color: #777;
        border-radius: 50%;
      }
    }
  }
}