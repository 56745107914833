@import '../../index.scss';

.newsletter {
  background: url(../../assets/images/letter-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center 0;
  background-attachment: fixed;

  @media(max-width: 768px) {
    background-position: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 42rem;
    max-width: 45rem;
    margin: 0 auto;
    padding: 5rem 0;

    @media(min-width: 600px) {
      margin-right: 5%;
    }

    h3 {
      font-size: 2.2rem;
      color: $dark-color;
      padding-bottom: .5rem;
      font-weight: bold;
      text-align: center;
    }

    .box {
      width: 80%;
      margin: 0.7rem 0;
      padding: 1rem 1.2rem;
      font-size: 1.6rem;
      color: $black;
      border-radius: 0.5rem;
      text-transform: none;
    }

  }
}