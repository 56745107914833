@import '../../variables.scss';

.login-form-container-info {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  align-items: center;
  width: 100vw;
  height: 20vh;
  padding-top: 40vh;
  text-transform: lowercase;

  .close-login-btn {
    position: absolute;
    top: 1.5rem;
    right: 2.5rem;
    font-size: 5rem;
    color: $black;
    cursor: pointer;

    &:hover {
      svg {
        color: $green;
      }
    }
  }

  .log-info {
    font-size: 2rem;
    text-transform: unset;
    text-transform: none;

    strong {
      padding-left: 1rem;
      text-transform: unset;
      text-transform: none;
    }
  }

  .btn-login--info {
    margin-top: 2rem;
    display: inline-block;
    padding: .9rem 3rem;
    border-radius: .5rem;
    color: #fff;
    background-color: $green;
    font-size: 1.7rem;
    text-align: center;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: $dark-color;
    }
  }
}