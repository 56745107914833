@import '../../index.scss';

.heading {
  h1 {
    font-size: 4rem;
    text-align: left;
    margin: 2rem 0;
    text-transform: none;
  }
}

.container-shopping {
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "list checkout"
    "list checkout"
  ;

  @media (max-width:800px) {
    grid-template-areas:
      "checkout checkout"
      "list list"
    ;
  }

  .shopping-lists-group {
    grid-area: list;
    display: flex;
    flex-direction: column;

    .shopping-list--cart {

      p {
        display: flex;
        font-size: 2rem;
        margin: 2rem 0;
        text-align: left;

        span {
          text-transform: normal;
        }
      }

      .cart-one {
        display: grid;
        grid-template-columns: auto minmax(200px, 300px) auto auto;
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
          " img info count price"
          " img info count price"
          " img quant count price"
          " action action count price"
        ;

        @media (max-width: 1150px) {
          grid-template-columns: auto 1fr auto auto;
          grid-template-rows: repeat(5, auto);
          grid-template-areas:
            " img info info price"
            " img info info price"
            " img count count price"
            " img quant quant price"
            " action action action price"
          ;
        }

        @media (max-width: 600px) {
          grid-template-columns: auto 1fr auto auto;
          // grid-template-rows: repeat(5, auto);
          grid-template-areas:
            " img info info info"
            " img info info info"
            " img info info info"
            " count count price price"
            " action action action action"
          ;
        }

        border-bottom: 1px solid #555;
        padding-bottom: 1rem;
        margin: 2rem 0;

        img {
          grid-area: img;
          height: 15rem;
          margin-right: 2rem;
          cursor: pointer;

          @media (max-width:850px) {
            height: 15rem;
          }
        }

        .book-short-info {
          grid-area: info;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h4 {
            font-size: 2.2rem;
            font-weight: 800;
            color: #000;
            cursor: pointer;
          }

          span {
            font-size: 1.5rem;
            font-weight: 400;
            color: #333;
          }

        }

        .book-count {
          margin: 0 3rem;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-self: center;

          @media (max-width:1150px) {
            margin: 0;
          }

          @media (max-width:600px) {
            margin: 2rem 0;
            justify-content: center;
          }

          div {
            font-size: 1.5rem;
            // margin-bottom: 2rem;
            text-align: center;

            @media (max-width:1150px) {
              text-align: left;
            }
          }

          &--controller {
            display: inline-block;
            padding: 1rem;
            font-size: 2rem;
            border: 0.5px solid #777;
            border-radius: 5px;
            cursor: pointer;
          }

          &--result {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin: 0 1rem;
            text-align: center;
            line-height: 2rem;
            font-size: 2rem;
            color: $green;
          }

        }

        .book-quantity {
          grid-area: quant;
          display: block;
          margin: 1rem 0;
          text-transform: none;
          font-size: 1.5rem;
          font-weight: 400;
          color: #333;

          @media(max-width:600px) {
            display: none;
          }

          em {
            text-transform: none;
            color: red;
          }
        }


        .book-price {
          grid-area: price;

          display: flex;
          flex-direction: column;
          margin-right: 4rem;
          align-items: center;
          // justify-content: center;

          @media (max-width: 850px) {
            align-items: start;
          }

          @media(max-width:600px) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }

          div {
            font-size: 1.5rem;
            margin-bottom: 1rem;

            @media(max-width:600px) {
              display: none;
            }
          }

          &-current {
            font-size: 2rem;
            font-weight: 400;
            color: $green;
          }

          &-old {
            font-size: 1.5rem;
            font-weight: 400;
            color: #777;
            text-decoration: line-through;

            @media(max-width: 600px) {
              display: none;
            }
          }
        }


        .book-action {
          grid-area: action;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 1rem;
          // margin: 0 4rem;
          margin-right: 4rem;


          span {
            margin-right: 2rem;
            padding: 1rem 0;
            font-size: 1.5rem;
            font-weight: 400;
            text-transform: initial;
            color: darken($green , 10%);
            cursor: pointer;

            &:hover {
              color: $green;
            }
          }

          @media (max-width: 1024px) {
            flex-basis: 100%;

          }
        }
      }
    }

    .shopping-list--cart--wishlisted {
      @extend .shopping-list--cart;
      margin-top: 5rem;

      section {
        padding: 0 0;
      }

      .heading {
        display: none;
      }

      .heading-shopping {
        display: block;
      }

      .cart-one .book-action span {
        margin-right: 2rem;
        padding: 1rem 0;
        font-size: 1.5rem;
        font-weight: 400;
        text-transform: initial;
        color: darken($green , 10%);
        cursor: pointer;
        background-color: #fff;

        &:hover {
          color: $green;
        }
      }
    }
  }

  .shopping-checkout--container {
    grid-area: checkout;
    min-width: 260px;
    margin-bottom: 4rem;

    .shopping-checkout-box {
      text-align: left;
    }

    h4 {
      display: inline-block;
      margin-right: 2rem;
      font-size: 3rem;
      color: #777;
    }

    p {
      display: inline-block;
      font-size: 3rem;
      font-weight: 800;

    }

    .btn-block {
      display: inline-block;
      width: 100%;
      padding: 1rem;
    }

  }

}