@import '../../index.scss';

.box__review {
  width: 30rem;
  border: $border;
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;

  &:hover {
    border: $border-hover;
  }

  img {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
  }

  h3 {
    color: $green;
    font-size: 2.5rem;
    padding-bottom: .5rem 0;
  }

  p {
    color: $light-color;
    font-size: 1.4rem;
    // line-height: 2;
    padding: 1rem 0;
  }

  .stars {
    font-size: 1.5rem;
    color: $green;
  }
}