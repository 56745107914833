@import '../../index.scss';

.bottom-navbar {
  text-align: center;
  background-color: $green;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
  @extend %flex-center;

  @media (max-width: 768px) {
    display: block;
  }

  a {
    display: inline-block;
    padding: 2rem;
    color: #fff;

    &:hover {
      background-color: $dark-color;
    }

    svg {
      font-size: 2.5rem;
    }
  }
}