@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Poppins:wght@100;300;400;500;600&display=swap');
@import './variables.scss';

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: .9rem 3rem;
  border-radius: .5rem;
  color: #fff;
  background-color: $green;
  font-size: 1.7rem;
  text-align: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: $dark-color;
  }
}