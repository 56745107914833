.delivery__container {
  // margin-top: 4rem;

  h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    letter-spacing: 1.8px;
    font-weight: 600;
    text-transform: initial;
  }


}

.delivery-group {
  margin: 1.5rem 0;

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: initial;
    line-height: 3rem;
  }
}

.deliver-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    .circle_outside {
      border: 2px solid #333;
    }
  }


}

.deliver-short-info {
  margin: 1rem 0;

}

.circle_outside {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 1px solid #999;
  margin-right: 1rem;

  &.selected {
    border: 2px solid green;
  }
}

.circle-inside {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: green;
}

.deliver-title {
  display: flex;
  align-items: center;
}

.delivery-price {
  margin-right: 1rem;
}