@import '../../index.scss';

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;

  @media (max-width:500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .box {
    @media (max-width:500px) {
      width: 100%;
    }

    h3 {
      font-size: 2.2rem;
      color: $black;
      padding: 1rem 0;
    }

    a {
      display: inline-block;
      width: 100%;
      font-size: 1.4rem;
      color: $light-color;
      padding: 1rem 0;

      display: flex;
      justify-content: flex-start;
      margin-left: 4rem;

      &:hover {
        svg {
          margin-right: 1rem;
        }
      }

      svg {
        color: $green;
        padding-right: 0.5rem;
      }
    }

    .map {
      width: 100%;
    }
  }
}

.footer .share {
  @extend %flex-center;
  padding: 1rem 0;

  a {
    height: 5rem;
    width: 5rem;
    font-size: 2rem;
    line-height: 5rem;
    background-color: $green;
    color: #fff;
    margin: 0 .5rem;
    border-radius: 50%;
    @extend %flex-center;

    &:hover {
      background: $black;
    }
  }
}

.footer .credit {
  border-top: $border;
  margin-top: 2rem;
  padding: 0 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: $light-color;
  text-align: center;

  span {
    color: $green;
  }
}