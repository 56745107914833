@import '../../index.scss';


.add-book-container {
  @extend %flex-center;
  flex-wrap: wrap;
  // align-items: flex-start;


  background-color: rgba(255, 255, 255, .9);
  height: 100vh;
  width: 100%;
  text-transform: none;


  form {
    border: $border;
    background-color: #fff;
    width: 50%;
    padding: 2rem;
    box-shadow: $box-shadow;
    border-radius: .5rem;
    margin: 2rem;

    @media (max-width:1024px) {
      width: 80%;
    }

    h3 {
      font-size: 2.5rem;
      text-transform: uppercase;
      color: $black;
      text-align: center;
      margin-bottom: 2rem;

      svg {
        color: $green;
      }
    }

    span {
      display: inline-block;
      font-size: 1.5rem;
      padding-top: 1rem;
      font-weight: bold;
    }

    .box {
      width: 100%;
      margin: .5rem 0;
      font-size: 1.2rem;
      border: $border;
      border-radius: .5rem;
      padding: 0.7rem 1.2rem;
      color: $black;
      text-transform: none;
      background-color: #ddd;
      text-align: left;
    }

    textarea.box {
      height: 15rem;
      overflow-y: scroll;
    }




  }

  .close-login-btn {
    position: absolute;
    padding: 1rem;
    top: 1rem;
    right: 2rem;
    font-size: 5rem;
    color: $black;
    cursor: pointer;

    &:hover {
      svg {
        color: $green;
      }
    }
  }


}