@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Poppins:wght@100;300;400;500;600&display=swap');
@import './variables.scss';

* {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all .2s linear;
  transition: width none;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 5rem;

  @media (max-width:991px) {
    font-size: 55%;
  }

  @media (max-width:768px) {
    scroll-padding-top: 0;
  }

  @media (max-width:450px) {
    font-size: 50%;
  }
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: $black;
}

body {
  position: relative;

  @media (max-width:768px) {
    padding-bottom: 6rem;
  }
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: .9rem 3rem;
  border-radius: .5rem;
  color: #fff;
  background-color: $green;
  font-size: 1.7rem;
  text-align: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: $dark-color;
  }
}

section {
  padding: 5rem 9%;

  @media (max-width:991px) {
    padding: 3rem 2rem;
  }

}