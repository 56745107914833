@import '../../index.scss';

.icons-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;

  @media (max-width:500px) {
    margin-left: 4rem;
  }

  .icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 6rem;
    // margin: 0 auto;
    gap: 1.5rem;
    padding: 2rem o;

    svg {
      font-size: 3.5rem;
      color: $green;
    }

    h3 {
      font-size: 2.2rem;
      color: $black;
      padding-bottom: 0.5rem;
    }

    p {
      font-size: 1.4rem;
      color: $light-color;
    }

  }


}